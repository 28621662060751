<template>
  <v-row>
    <v-col class="mx-auto" cols="12" lg="7" xl="5">
      <v-card class="pa-4" flat>
        <v-card-title class="d-block px-0">
          <back-button-component/>
          <div class="d-flex">
            <span class="font-weight-bold d-block">Top 5 Priorities</span>
          </div>
        </v-card-title>
        <v-card rounded-lg>
          <v-card-text>
            <template v-if="priorities.length > 0">
              <v-card-subtitle>Today's priorities</v-card-subtitle>
              <template v-for="(priority, i) in priorities">
                <v-sheet class="pa-4" elevation="0" :key="priority.description">
                  <div class="d-flex flex-column justify-start">
                    <v-checkbox v-model="priority.status"
                                :readonly="priority.status === 1"
                                @change="changeStatus(priority.id, priority.status)">
                      <template v-slot:label>
                        <span class="text-h6 font-weight-bold">Top #{{ i + 1 }} priority</span>
                      </template>
                    </v-checkbox>
                    <span class="font-weight-light ml-9">{{ priority.description }}</span>
                  </div>
                </v-sheet>
              </template>
            </template>
            <template v-else>
              <v-card-title>
                <v-icon large color="warning">mb-exclamation-outline</v-icon>
                <span class="text-h6 font-weight-bold warning--text ml-3">You didn't add today's priorities yesterday!</span>
              </v-card-title>
            </template>
          </v-card-text>
        </v-card>
      </v-card>
    </v-col>
    <v-col class="mx-auto" cols="12" lg="5" xl="7">
      <v-card class="pa-4" flat>
        <v-card-title class="d-block px-0">
          <div class="d-flex mt-12">
            <span class="font-weight-bold d-block">Top 5 Priorities Archive</span>
          </div>
        </v-card-title>
        <v-card flat>
          <v-tabs id="topTabs" background-color="#F6F6F6"
                  class="rounded-xl"
                  v-model="tab" grow centered hide-slider
                  :style="`height: ${$vuetify.breakpoint.height * 0.05}px; min-height: ${$vuetify.breakpoint.height * 0.05}px`">
            <template v-for="(item, key) in archive">
              <v-tab class="rounded-xl" :key="key"
                     :active-class="key === tab ? 'elevation-2 white' : ''"
                     :ripple="false">{{ item.name }}</v-tab>
            </template>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <template v-for="item of archive">
              <v-tab-item :key="item.name" class="pa-4">
                <template v-if="item.records.length > 0">
                  <v-expansion-panels accordion>
                    <v-expansion-panel v-for="record in item.records" :key="record.id">
                      <v-expansion-panel-header>
                        <v-row no-gutters>
                          <v-col cols="4">
                            <span class="font-weight-bold">{{ record['created_at'] }}</span>
                          </v-col>
                          <v-col cols="4">
                            <span style="color: #8F8D89">Closed: {{ record.closed }}</span>
                          </v-col>
                          <v-col cols="4">
                            <span style="color: #8F8D89">Open: {{ record.open }}</span>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <template v-for="(priority, i) in record.priorities">
                          <v-sheet class="pa-4" elevation="0" :key="priority.description">
                            <div class="d-flex flex-column justify-start">
                              <div class="pa-0">
                                <v-icon color="primary">{{ priority.status ? 'mdi-checkbox-outline' : 'mdi-checkbox-blank-outline'}}</v-icon>
                                <span class="text-h6 font-weight-bold ml-2">Top #{{ i + 1 }} priority</span>
                              </div>
                              <span class="font-weight-light ml-9">{{ priority.description }}</span>
                            </div>
                          </v-sheet>
                        </template>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </template>
                <template v-else>
                  <v-card-title>
                    <v-icon large color="warning">mb-exclamation-outline</v-icon>
                    <span class="text-h6 font-weight-bold warning--text ml-3">Nothing to show in archive</span>
                  </v-card-title>
                </template>
              </v-tab-item>
            </template>
          </v-tabs-items>
        </v-card>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>

export default {
  name: "Top",
  data () {
    return {
      active: 'week',
      tab: 0,
      priorities: [],
      outdated: [],
      archive: {},
      routes: {
        create: 'dashboard.wows.create',
        show: 'dashboard.wows.show',
        edit: 'dashboard.wows.edit'
      }
    }
  },
  beforeMount() {
    this.getPriorities()
  },
  methods: {
    setTab (key) {
      this.active = key
    },
    changeStatus (id, status) {
      this.$http.patch(`${process.env.VUE_APP_API_URL}/priority/${id}`, {
        status: status
      })
          .then(() => {
            this.getPriorities()
          })
    },
    getPriorities () {
      this.$http.get(`${process.env.VUE_APP_API_URL}/top`)
      .then(response => {
        this.priorities = response.data.current
        this.outdated = response.data.outdated
        this.archive = response.data.archive
        if (this.priorities.length === 0 && this.outdated.length > 0) {
          this.priorities = this.outdated
        }
      })
    }
  }
}
</script>